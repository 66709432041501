:root {
  --border-radius: 4px;
  --controls-transition-duration: 0.3s;
}

video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display {
    display: inline-block;
}

.Player {
  position: relative;
  width: 100%;
  max-width: 900px;
  border-radius: var(--border-radius);
}

.Player-videoBox {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.Player-video {
  position: absolute;
  border-radius: var(--border-radius);
  overflow: hidden;
  top:0; right:0; bottom:0; left:0; width:'100%'; height: '100%'
}

.Player-video video {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}