.columnHeader {
  font-weight: 600;
}

.columnHeaderRight {
  font-weight: 600;
  text-align: right;
}

.even {
  background-color: #e5e5e5;
}
.odd {
  background-color: white;
}