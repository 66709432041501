.beg-footer {
  min-width: 100%;
  min-height: 38px;
  max-height: 38px;
  height: 38px;
  position:absolute;
  bottom: 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.26);
  backdrop-filter: blur(2px);  
}