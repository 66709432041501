body {
  --text-color: #7B7B7B;
  --primary-color: #D1337F;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.amount-input:focus {
  outline: none;
}

.end-date-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.end-date-flex-item {
  margin-top: 1rem;
}

.end-date-item-text {
  flex-shrink: 0;
  width: auto;
  text-align: start;
  padding-bottom: 24px;
}

.end-date-item-icon {
  margin-top: 0px;
  margin-left: 4px;
  margin-right: 2rem;
  color: var(--text-color);
  flex-grow: 0;
  width: auto;
}

.end-date-item-date {
  flex-grow: 2;
  flex-shrink: 0;
  width:fit-content;
}

.editor {
  border-top: 1px solid var(--text-color);
  min-height: 7em;
  max-height: 7em;
}

.wrapper {
  border: 1px solid var(--text-color);
  border-radius: 4px;
}

.editorEdit {
  border-top: 1px solid var(--text-color);
  min-height: 14em;
  max-height: 14em;
}

.editorSuccess {
  border-top: 1px solid var(--text-color);
  min-height: 7em;
  max-height: 7em;
}



.radio-button-frame {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  background-color: var(--primary-color);
  padding: 6px 14px;
  color: white;
  
}

.radio-button-description {
  margin-left: 2rem;
  margin-top: -.5rem;
  margin-bottom: .5rem;
  font-size: small;
}

.tip {
  font-size: smaller;
  padding-top: 1rem;
  padding-bottom: 1rem;
}