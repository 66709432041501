/* columns */
.grid-column-header {
  background-color: #c5c5c5;
  line-height: 20px;
  min-height: 56px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  font-size: 13px;
  overflow: hidden !important;
  white-space: normal !important;
}

/* remove column seperator */
.MuiDataGrid-columnSeparator {
  /* display: none !important; */
}


/* data grid */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiDataGrid-columnHeader {
  outline: none !important;
}
.MuiDataGrid-columnHeaders {
  background-color: none;
}
.MuiDataGrid-root {
  border: none !important;
}


/* alternating row colors */
.odd {
  background-color: #ffffff;
  font-size: 13px;
}
.even {
  background-color: #e5e5e5;
  font-size: 13px;
}


/* grid toolbar */
.grid-button {
  padding: 6px 14px 6px 14px !important;
  margin-left: 14px !important;
}