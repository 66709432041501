.rounded-corners-gradient-borders {
  display: block;
  width: 220px;
  height: 220px;
  border: double 2px transparent;
  border-radius: 8px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #676DFF 11.35%, #ED6C79 87.93%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.rounded-corners-borders {
  display: block;
  width: 220px;
  height: 220px;
  border: double 2px transparent;
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.carousel-item {
  width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}

.carousel-image {
  min-height: 216px;
  max-height: 216px;
  height: 100%;
  width: 100%;
  background-color: black;
  border: none;
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
}

.carousel-indicator-icon { 
  min-height: 10px;
  min-width: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid white;
}