
.skipNext, .skipPrevious {
  position: absolute;
  top:44%;
  bottom:56%;
  background: rgba(204, 204, 204, 0.1);
  border-radius: 50%;
  padding:6px;
  z-index: 1;
  cursor: pointer;
}

.skipNext:hover, .skipPrevious:hover {
  background: rgba(204, 204, 204, 0.8);
  background-color:black;
  color: white
}
