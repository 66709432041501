@import url('https://fonts.cdnfonts.com/css/hey-comic');
@import url('https://fonts.cdnfonts.com/css/lato');
                

html,
body {
  font-family: 'Lato', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  flex-shrink: 0;
  margin-top: 68px;
  padding-left: 24px;
  padding-right: 24px;
  background: linear-gradient(270deg, #D0347E 0%, #B33E8D 35.45%, #8D4DA3 83.38%, #7952AB 122.85%);
}